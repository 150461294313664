@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Outfit:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.heading{
  font-size: 140px;
  font-weight: 400;
   line-height: 125px;
}
.and{
  color: #EEC044;
}

@font-face {
  font-family: myFirstFont;
  src: url(/public/chunkfive/ChunkFive-Regular.otf);
}

.montserrat{
  font-family: "Montserrat", sans-serif;
 
}
.Manrope{
  font-family: "Manrope", sans-serif;
 
}
.DMSans{
  font-family: "DM Sans", sans-serif;
 
}
.Poppins{
  font-family: "Poppins", sans-serif;
 
}
.Outfit{
  font-family: "Outfit", sans-serif;
 
}


.myFirstFont{ font-family: myFirstFont}

.button-down{
  background-color: #C83425;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  padding: 10px 20px;
  color: white;
}
.nav-item{
  display: block;
  padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border-radius: 0.25rem;
    color:#878680

}
.nav-item.active{
  color:  rgb(224 36 36 / 1);;
}